<template>
  <div class="admin-wrap">
    <a class="btn-new" href="/admin/staffs/0"></a>
    <AdminMenu active-item="staffs" />
    <div class="admin-main">
      <AdminHeader title="員工管理" />
      <div class="employee-list">
        <div class="site-search site-search--admin">
          <input type="text" id="inputSearch" v-model="keyword">
          <button>搜尋</button>
        </div>
        <table class="admin-table sortable">
          <thead>
            <tr>
              <th>編號</th>
              <th>姓名</th>
              <th>電話</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="staff in filteredList" :key="staff">
              <td>{{staff.id}}</td>
              <td>{{staff.fullName}}</td>
              <td>{{staff.phone}}</td>
              <td>
                <a class="admin-btn" :href="`/admin/staffs/${staff.id}/report`">報表</a>
                <a class="admin-btn" :href="`/admin/staffs/${staff.id}`">編輯</a>
                <a class="admin-btn" href="#" @click="showConfirmDelete(staff.id)">刪除</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!--是否確認刪除帳號?-->
    <confirm-delete-dialog :visible="isShowConfirmDelete" title="是否確認刪除？" message="此員工相關資料將會保存於資料庫，但不再顯示。" @confirm="onDelete" @cancel="hideConfirmDelete" />
  </div>
</template>

<script>
import AdminMenu from '../../components/AdminMenu'
import AdminHeader from '../../components/AdminHeader'
import ConfirmDeleteDialog from '@/components/ConfirmDeleteDialog'
import {
  apiGetStaffs,
  apiDeleteStaff
} from '@/api/v1/admin'

export default {
  name: 'Staffs',
  components: { AdminMenu, AdminHeader, ConfirmDeleteDialog },
  computed: {
    filteredList () {
      return this.staffs.filter(p => (p.fullName !== null && p.fullName.includes(this.keyword)) || (p.phone !== null && p.phone.includes(this.keyword)))
    }
  },
  created () {
    this.fetchData()
  },
  data () {
    return {
      isShowConfirmDelete: false,
      selectedItemId: 0,
      staffs: [],
      keyword: ''
    }
  },
  methods: {
    fetchData () {
      apiGetStaffs().then(data => {
        if (data.error) {
          console.log(data.error.message)
        } else {
          this.staffs = data.content
        }
      })
    },
    showConfirmDelete (itemId) {
      this.isShowConfirmDelete = true
      this.selectedItemId = itemId
    },
    hideConfirmDelete () {
      this.isShowConfirmDelete = false
      this.selectedItemId = 0
    },
    onDelete () {
      apiDeleteStaff(this.selectedItemId).then(data => {
        if (data.error) {
          this.$swal('刪除失敗', data.error.message, 'warning')
        } else {
          this.fetchData()
        }
        this.hideConfirmDelete()
      })
    }
  }
}
</script>

<style>

</style>
